exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-article-view-all-tsx": () => import("./../../../src/pages/ArticleViewAll.tsx" /* webpackChunkName: "component---src-pages-article-view-all-tsx" */),
  "component---src-pages-articles-data-analytics-big-data-analytics-for-your-bussiness-tsx": () => import("./../../../src/pages/articles/data-analytics/big-data-analytics-for-your-bussiness.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-big-data-analytics-for-your-bussiness-tsx" */),
  "component---src-pages-articles-data-analytics-big-data-as-a-service-tsx": () => import("./../../../src/pages/articles/data-analytics/big-data-as-a-service.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-big-data-as-a-service-tsx" */),
  "component---src-pages-articles-data-analytics-data-visualization-tsx": () => import("./../../../src/pages/articles/data-analytics/data-visualization.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-data-visualization-tsx" */),
  "component---src-pages-articles-data-analytics-data-vs-bussiness-analytics-tsx": () => import("./../../../src/pages/articles/data-analytics/data-vs-bussiness-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-data-vs-bussiness-analytics-tsx" */),
  "component---src-pages-articles-data-analytics-how-ai-is-transforming-bussiness-tsx": () => import("./../../../src/pages/articles/data-analytics/how-ai-is-transforming-bussiness.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-how-ai-is-transforming-bussiness-tsx" */),
  "component---src-pages-articles-data-analytics-log-analytics-tsx": () => import("./../../../src/pages/articles/data-analytics/log-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-log-analytics-tsx" */),
  "component---src-pages-articles-data-analytics-predictive-analytics-tsx": () => import("./../../../src/pages/articles/data-analytics/predictive-analytics.tsx" /* webpackChunkName: "component---src-pages-articles-data-analytics-predictive-analytics-tsx" */),
  "component---src-pages-articles-detail-view-tsx": () => import("./../../../src/pages/ArticlesDetailView.tsx" /* webpackChunkName: "component---src-pages-articles-detail-view-tsx" */),
  "component---src-pages-articles-digital-enterprise-application-migration-to-cloud-tsx": () => import("./../../../src/pages/articles/digital-enterprise/application-migration-to-cloud.tsx" /* webpackChunkName: "component---src-pages-articles-digital-enterprise-application-migration-to-cloud-tsx" */),
  "component---src-pages-articles-digital-enterprise-application-modernization-in-cloud-tsx": () => import("./../../../src/pages/articles/digital-enterprise/application-modernization-in-cloud.tsx" /* webpackChunkName: "component---src-pages-articles-digital-enterprise-application-modernization-in-cloud-tsx" */),
  "component---src-pages-articles-digital-enterprise-data-migration-to-cloud-tsx": () => import("./../../../src/pages/articles/digital-enterprise/data-migration-to-cloud.tsx" /* webpackChunkName: "component---src-pages-articles-digital-enterprise-data-migration-to-cloud-tsx" */),
  "component---src-pages-articles-digital-enterprise-edi-vs-api-tsx": () => import("./../../../src/pages/articles/digital-enterprise/edi-vs-api.tsx" /* webpackChunkName: "component---src-pages-articles-digital-enterprise-edi-vs-api-tsx" */),
  "component---src-pages-articles-digital-enterprise-enhance-your-development-tsx": () => import("./../../../src/pages/articles/digital-enterprise/enhance-your-development.tsx" /* webpackChunkName: "component---src-pages-articles-digital-enterprise-enhance-your-development-tsx" */),
  "component---src-pages-articles-digital-infrastructure-application-modernization-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/application-modernization.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-application-modernization-tsx" */),
  "component---src-pages-articles-digital-infrastructure-aws-cloud-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/aws-cloud.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-aws-cloud-tsx" */),
  "component---src-pages-articles-digital-infrastructure-azure-cloud-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/azure-cloud.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-azure-cloud-tsx" */),
  "component---src-pages-articles-digital-infrastructure-digital-era-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/digital-era.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-digital-era-tsx" */),
  "component---src-pages-articles-digital-infrastructure-digital-transformation-in-todays-world-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/digital-transformation-in-todays-world.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-digital-transformation-in-todays-world-tsx" */),
  "component---src-pages-articles-digital-infrastructure-five-ways-to-improve-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/five-ways-to-improve.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-five-ways-to-improve-tsx" */),
  "component---src-pages-articles-digital-infrastructure-green-cloud-computing-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/green-cloud-computing.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-green-cloud-computing-tsx" */),
  "component---src-pages-articles-digital-infrastructure-innovative-approach-to-cloud-computing-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/innovative-approach-to-cloud-computing.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-innovative-approach-to-cloud-computing-tsx" */),
  "component---src-pages-articles-digital-infrastructure-trends-in-cloud-computing-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/trends-in-cloud-computing.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-trends-in-cloud-computing-tsx" */),
  "component---src-pages-articles-digital-infrastructure-unlock-the-value-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/unlock-the-value.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-unlock-the-value-tsx" */),
  "component---src-pages-articles-digital-infrastructure-why-should-your-organization-tsx": () => import("./../../../src/pages/articles/digital-infrastructure/why-should-your-organization.tsx" /* webpackChunkName: "component---src-pages-articles-digital-infrastructure-why-should-your-organization-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-articles-white-papers-overview-tsx": () => import("./../../../src/pages/articles/white-papers/overview.tsx" /* webpackChunkName: "component---src-pages-articles-white-papers-overview-tsx" */),
  "component---src-pages-blog-optix-cloud-finops-tsx": () => import("./../../../src/pages/blog/optix/cloud-finops.tsx" /* webpackChunkName: "component---src-pages-blog-optix-cloud-finops-tsx" */),
  "component---src-pages-botx-index-tsx": () => import("./../../../src/pages/botx/index.tsx" /* webpackChunkName: "component---src-pages-botx-index-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-data-analytics-tsx": () => import("./../../../src/pages/data-analytics.tsx" /* webpackChunkName: "component---src-pages-data-analytics-tsx" */),
  "component---src-pages-digital-enterprise-tsx": () => import("./../../../src/pages/digital-enterprise.tsx" /* webpackChunkName: "component---src-pages-digital-enterprise-tsx" */),
  "component---src-pages-digital-infrastructure-tsx": () => import("./../../../src/pages/digital-infrastructure.tsx" /* webpackChunkName: "component---src-pages-digital-infrastructure-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-details-tsx": () => import("./../../../src/pages/JobDetails.tsx" /* webpackChunkName: "component---src-pages-job-details-tsx" */),
  "component---src-pages-lost-and-found-index-tsx": () => import("./../../../src/pages/lost-and-found/index.tsx" /* webpackChunkName: "component---src-pages-lost-and-found-index-tsx" */),
  "component---src-pages-opti-x-index-tsx": () => import("./../../../src/pages/optiX/index.tsx" /* webpackChunkName: "component---src-pages-opti-x-index-tsx" */),
  "component---src-pages-platforms-index-tsx": () => import("./../../../src/pages/platforms/index.tsx" /* webpackChunkName: "component---src-pages-platforms-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-success-stories-data-analytics-data-analytic-tsx": () => import("./../../../src/pages/success-stories/data-analytics/data-analytic.tsx" /* webpackChunkName: "component---src-pages-success-stories-data-analytics-data-analytic-tsx" */),
  "component---src-pages-success-stories-data-analytics-data-as-a-service-tsx": () => import("./../../../src/pages/success-stories/data-analytics/data-as-a-service.tsx" /* webpackChunkName: "component---src-pages-success-stories-data-analytics-data-as-a-service-tsx" */),
  "component---src-pages-success-stories-data-analytics-enterprise-file-search-tsx": () => import("./../../../src/pages/success-stories/data-analytics/enterprise-file-search.tsx" /* webpackChunkName: "component---src-pages-success-stories-data-analytics-enterprise-file-search-tsx" */),
  "component---src-pages-success-stories-detail-view-tsx": () => import("./../../../src/pages/SuccessStoriesDetailView.tsx" /* webpackChunkName: "component---src-pages-success-stories-detail-view-tsx" */),
  "component---src-pages-success-stories-digital-enterprise-leader-board-application-tsx": () => import("./../../../src/pages/success-stories/digital-enterprise/leader-board-application.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-enterprise-leader-board-application-tsx" */),
  "component---src-pages-success-stories-digital-enterprise-modernize-and-migrate-tsx": () => import("./../../../src/pages/success-stories/digital-enterprise/modernize-and-migrate.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-enterprise-modernize-and-migrate-tsx" */),
  "component---src-pages-success-stories-digital-enterprise-modernizing-shop-floor-tsx": () => import("./../../../src/pages/success-stories/digital-enterprise/modernizing-shop-floor.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-enterprise-modernizing-shop-floor-tsx" */),
  "component---src-pages-success-stories-digital-enterprise-on-premises-monolithic-tsx": () => import("./../../../src/pages/success-stories/digital-enterprise/on-premises-monolithic.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-enterprise-on-premises-monolithic-tsx" */),
  "component---src-pages-success-stories-digital-enterprise-setup-aws-cloud-tsx": () => import("./../../../src/pages/success-stories/digital-enterprise/setup-aws-cloud.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-enterprise-setup-aws-cloud-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-aws-cloud-operations-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/aws-cloud-operations.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-aws-cloud-operations-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-cloud-guardrails-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/cloud-guardrails.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-cloud-guardrails-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-consumer-staples-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/consumer-staples.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-consumer-staples-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-digital-infrastructure-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/digital-infrastructure.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-digital-infrastructure-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-integrating-infrastructure-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/integrating-infrastructure.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-integrating-infrastructure-tsx" */),
  "component---src-pages-success-stories-digital-infrastructure-six-r-framework-tsx": () => import("./../../../src/pages/success-stories/digital-infrastructure/six-r-framework.tsx" /* webpackChunkName: "component---src-pages-success-stories-digital-infrastructure-six-r-framework-tsx" */),
  "component---src-pages-success-stories-home-connected-cars-tsx": () => import("./../../../src/pages/success-stories/home/connected-cars.tsx" /* webpackChunkName: "component---src-pages-success-stories-home-connected-cars-tsx" */),
  "component---src-pages-success-stories-opti-x-cloud-finops-tsx": () => import("./../../../src/pages/success-stories/optiX/cloud-finops.tsx" /* webpackChunkName: "component---src-pages-success-stories-opti-x-cloud-finops-tsx" */)
}

